<template>
  <div class="row content-wrapper">
    <LoadingSpinner v-if="isLoading" />
    <div class="col-md-12" v-else>
      <div class="row">
        <div class="col-md-12">
          <div v-if="spot.main_video_url" class="main-video-wrapper">
            <div class="video-content">
              <!-- <div id="krpano" style="width: 100%; height: 400px"></div> -->
              <krpano
                id="krpano"
                :xml="xml"
                :initvars="{ video_url: spot.main_video_url }"
                :lazy-load="true"
                class="main-video-style"
              >
              </krpano>
            </div>
          </div>
          <div v-else class="text-center thumbnail-wrapper">
            <img :src="spot.thumbnail" :alt="spot.title" />
          </div>
        </div>
      </div>
      <div class="action-wrapper">
        <div class="action-btn active-cursor">
          <template v-if="this.$store.state.token">
            <template v-if="currentUserLike">
              <img
                src="../../assets/images/like_fill.png"
                alt=""
                @click="onDeleteLike"
              />
            </template>
            <template v-else>
              <img
                src="../../assets/images/like_stroke.png"
                alt=""
                @click="onClickLike"
              />
            </template>
          </template>
          <template v-else>
            <template>
              <img src="../../assets/images/like_stroke.png" alt="" />
            </template>
          </template>
          {{ likesCount }}
        </div>
        <div class="action-btn">
          <img src="../../assets/images/comment.png" alt="" />
          {{ commentsCount }}
        </div>
        <div class="action-btn active-cursor">
          <template v-if="this.$store.state.token">
            <template v-if="currentUserBookmark">
              <img
                src="../../assets/images/bookmark_fill.png"
                alt=""
                @click="onDeleteBookmark"
              />
            </template>
            <template v-else>
              <img
                src="../../assets/images/bookmark_stroke.png"
                alt=""
                @click="onClickBookmark"
              />
            </template>
          </template>
          <template v-else>
            <template>
              <img src="../../assets/images/bookmark_stroke.png" alt="" />
            </template>
          </template>
          {{ bookmarksCount }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3 class="spot-detail-title">{{ spot.name }}</h3>
          <h4 class="spot-detail-summary">{{ spot.summary }}</h4>
        </div>
        <div class="col-md-12">
          <div class="detail-hashtag-wrapper">
            <span v-for="tag in tags" :key="tag.id" class="detail-hashtag">
              <span>#</span>{{ tag }}
            </span>
          </div>
        </div>
        <hr />
      </div>
      <div class="row spot-info-wrapper">
        <div class="col-md-12 spot-desc-wrapper">
          <p>{{ spot.description }}</p>
        </div>
        <div class="col-md-12 spot-title-wrapper">
          <span class="spot-text-label">추천</span>
          <span class="spot-text-text">{{ spot.title }}</span>
        </div>
        <div class="col-md-12 spot-address-wrapper">
          <span class="spot-text-label">주소</span>
          <span class="spot-text-text">{{ spot.address }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="map" class="mb-4"></div>
        </div>
      </div>
      <div
        v-if="spot.reservation_link != null || spot.reservation_link != ''"
        class="row"
      >
        <div class="col-md-12 text-center spot-detail-reservation-wrapper">
          <a
            :href="spot.reservation_link"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-default reservation-link-btn"
            >예약하기</a
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2 class="spot-cmt-title">댓글</h2>
          <div
            v-for="comment in comments"
            :key="comment.id"
            class="row text-start spot-cmt-wrapper"
          >
            <div class="col-md-4 spot-cmt-nickname">{{ comment.nickname }}</div>
            <div class="col-md-8 spot-cmt-contents">{{ comment.contents }}</div>
          </div>
          <div class="row" v-if="this.$store.state.token">
            <div class="col-md-12 spot-cmt-input">
              <form @submit.prevent="onSubmitComment">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="comment"
                    placeholder="내용을 입력하세요."
                  />
                  <button
                    class="btn btn-default spot-cmt-submit-btn"
                    type="submit"
                  >
                    등록
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { fetchTags } from '@/api/spot';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import {
  setLike,
  deleteLike,
  deleteBookmark,
  setBookmark,
} from '../../api/profile';
import { fetchBookmarksCount, fetchLikesCount } from '../../api/spot';

export default {
  data() {
    return {
      tags: [],
      map: null,
      markers: [],
      infowindow: null,
      currentUserLike: false,
      currentUserBookmark: false,
      comment: '',
      isLoading: false,
      prevRoute: null,
      isActive: false,
      buttonText: 'Pause',
      reservationLink: null,
      xml: '/js/krpano-1.20/templates/xml/videopano.xml',
      initvars: { video_url: '' },
      spotVideoUrl: '',
      likesCount: 0,
      bookmarksCount: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  components: {
    LoadingSpinner,
  },
  computed: {
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/';
    },
    ...mapState([
      'spot',
      'pos',
      'lat',
      'lon',
      'isOk',
      'commentsCount',
      'currentUserLikes',
      'currentUserBookmarks',
      'comments',
    ]),
  },
  watch: {
    isOk() {
      this.initMap();
    },
    currentUserLikes() {
      this.checkUserLikes();
    },
    currentUserBookmarks() {
      this.checkUserBookmarks();
    },
  },
  created() {
    this.isLoading = true;
    this.fetchData();
    this.isLoading = false;
  },
  mounted() {
    window.kakao && window.kakao.maps
      ? this.initMap()
      : this.addKakaoMapScript();
  },
  methods: {
    addKakaoMapScript() {
      const script = document.createElement('script');
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        '//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6c27d38503ea42c4b2aa0595526c76a6';
      document.head.appendChild(script);
    },
    initMap() {
      const container = document.getElementById('map');
      const options = {
        center: new kakao.maps.LatLng(this.lat, this.lon),
        level: 3,
        draggable: false,
      };
      this.map = new kakao.maps.Map(container, options);
      // 마커가 표시될 위치입니다
      var markerPosition = new kakao.maps.LatLng(this.lat, this.lon);
      // 마커를 생성합니다
      var marker = new kakao.maps.Marker({
        position: markerPosition,
      });

      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(this.map);
    },
    ...mapActions([
      'FETCH_SPOT',
      'FETCH_COMMENTS_COUNT',
      'FETCH_USER_LIKES',
      'FETCH_USER_BOOKMARKS',
      'SET_COMMENT',
      'FETCH_COMMENTS',
    ]),
    fetchData() {
      let { id } = this.$route.params;
      let { email } = this.$store.state;
      window.scrollTo(0, 0);
      this.FETCH_SPOT(id);
      this.FETCH_COMMENTS_COUNT(id);
      this.FETCH_USER_LIKES('userpass:' + email);
      this.FETCH_USER_BOOKMARKS('userpass:' + email);
      this.FETCH_COMMENTS(id);

      fetchTags(id)
        .then((res) => {
          res.data.forEach((element) => {
            this.tags.push(element.tag);
          });
        })
        .catch((err) => {
          console.log(err);
        });

      fetchLikesCount(id)
        .then((res) => {
          this.likesCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });

      fetchBookmarksCount(id)
        .then((res) => {
          this.bookmarksCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onClickLike() {
      const data = {
        id: 'userpass:' + this.$store.state.email,
        place: this.$route.params.id,
      };
      setLike(data)
        .then(() => {
          this.likesCount = this.likesCount + 1;
          this.currentUserLike = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onDeleteLike() {
      const data = {
        id: 'userpass:' + this.$store.state.email,
        place: this.$route.params.id,
      };
      deleteLike(data)
        .then(() => {
          this.likesCount = this.likesCount - 1;
          this.currentUserLike = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onClickBookmark() {
      const data = {
        id: 'userpass:' + this.$store.state.email,
        place: this.$route.params.id,
      };
      setBookmark(data)
        .then(() => {
          this.bookmarksCount = this.bookmarksCount + 1;
          this.currentUserBookmark = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onDeleteBookmark() {
      const data = {
        id: 'userpass:' + this.$store.state.email,
        place: this.$route.params.id,
      };
      deleteBookmark(data)
        .then(() => {
          this.bookmarksCount = this.bookmarksCount - 1;
          this.currentUserBookmark = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkUserLikes() {
      for (let i = 0; i < this.currentUserLikes.length; i++) {
        const el = this.currentUserLikes[i];
        if (el.place_id == this.$route.params.id) {
          this.currentUserLike = true;
          break;
        } else {
          this.currentUserLike = false;
        }
      }
    },
    checkUserBookmarks() {
      for (let i = 0; i < this.currentUserBookmarks.length; i++) {
        const el = this.currentUserBookmarks[i];
        if (el.place_id == this.$route.params.id) {
          this.currentUserBookmark = true;
          break;
        } else {
          this.currentUserBookmark = false;
        }
      }
    },
    onSubmitComment() {
      if (this.comment !== '') {
        const inputData = {
          place_id: this.$route.params.id,
          user_id: 'userpass:' + this.$store.state.email,
          contents: this.comment,
        };
        this.SET_COMMENT(inputData);
        // this.FETCH_COMMENTS(this.$route.params.id);
        // this.FETCH_COMMENTS_COUNT(this.$route.params.id);
        // this.comment = '';
        // this.$router.go();
      } else {
        this.$toast.error('내용을 입력해주세요');
      }
    },
  },
};
</script>

<style scoped>
#map {
  width: 744px;
  height: 400px;
}
a-scene {
  width: 744px;
  height: 440px;
}
.main-video-wrapper {
  position: relative;
}
.main-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
}
.main-video-btn:focus {
  box-shadow: none;
}
.main-video-wrapper:hover .main-video-btn {
  opacity: 0.7;
}
.thumbnail-content {
  height: 440px;
}
.thumbnail-content > img {
  height: 100%;
}
.reservation-link-btn {
  width: 100%;
}
.action-wrapper {
  margin-top: 15px;
  margin-bottom: 45px;
  text-align: right;
}
.action-btn {
  display: inline-block;
  margin-right: 50px;
}
.action-btn:last-child {
  margin-right: 30px;
}
.action-btn.active-cursor > img {
  cursor: pointer;
}
.spot-detail-title {
  margin-bottom: 10px;
  font-size: 30px;
  color: #333;
}
.spot-detail-summary {
  margin-bottom: 30px;
  font-size: 18px;
  color: #333;
}
.detail-hashtag-wrapper {
  margin-bottom: 35px;
}
.detail-hashtag {
  padding: 5px 15px;
  background-color: #44cac8;
  color: #fff;
  font-size: 14px;
  border-radius: 66px;
}
.spot-info-wrapper {
  margin-bottom: 40px;
}
.spot-desc-wrapper {
  margin-bottom: 30px;
}
.spot-desc-wrapper p {
  margin-bottom: 0;
  font-size: 16px;
  color: #333;
  line-height: 28px;
}
.spot-title-wrapper {
}
.spot-desc-wrapper {
}
.spot-text-label {
  font-size: 16px;
  font-weight: 700;
  color: #333;
}
.spot-text-label::after {
  content: '|';
  color: #7e7e7e;
  padding: 0 10px;
}
.spot-text-text {
  font-size: 16px;
  color: #333;
}
.spot-detail-reservation-wrapper {
  margin-bottom: 100px;
}
.reservation-link-btn {
  width: 250px;
  height: 60px;
  padding: 15px;
  background-color: #333;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.spot-cmt-title {
  margin: 0;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  border-bottom: 0.5px solid #c8c8c8;
}
.spot-cmt-wrapper {
  margin-top: 25px;
  padding-bottom: 7px;
}
.spot-cmt-nickname {
  font-size: 16px;
  font-weight: 700;
  color: #333;
}
.spot-cmt-contents {
  font-size: 16px;
  color: #333;
}
.spot-cmt-input {
  margin-top: 25px;
}
.spot-cmt-submit-btn {
  background-color: #333;
  color: #fff;
}
.a-enter-vr-button {
  bottom: auto !important;
  top: 10px;
}
.main-video-style {
  width: 100%;
  height: 400px;
}
@media screen and (max-width: 767px) {
  #map {
    width: 100%;
    height: 300px;
  }
  a-scene {
    width: 100%;
    height: 300px;
  }
  .thumbnail-wrapper > img {
    width: 100%;
    height: auto;
  }
  .action-wrapper .action-btn {
    display: inline-block;
    margin-right: 25px;
  }
  .main-video-style {
    width: 100%;
    height: 400px;
  }
}
</style>
