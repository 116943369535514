<template>
  <DetailComponent />
</template>

<script>
import DetailComponent from '@/components/spots/DetailComponent.vue';

export default {
  components: {
    DetailComponent,
  },
};
</script>

<style></style>
